* {
	box-sizing: border-box;
}

html {
	-webkit-text-size-adjust: 100%; // for iOS
	box-direction: normal;
	font-family: $root-font-family;
	font-size: $small-device-font-size;
	line-height: $root-line-height;
	font-weight: $root-font-weight;
}

html, body {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4 {
	font-weight: $heading-font-weight;
}

h1 {
	font-size: $h1-font-size;
	line-height: $h1-line-height;
	margin-bottom: 1rem;
}

h2 {
	font-size: $h2-font-size;
	margin-bottom: .5rem;
}

h3 {
	font-size: $h3-font-size;
}

h4 {
	font-size: $h4-font-size;
}

h5 {
	font-size: $h5-font-size;
}

figure {
	max-width: 100%;
	margin: 0;
	padding: 0;
}

%quotebox {
	padding: .8889rem;
	margin-top: 1rem;
	margin-left: 0px;
	margin-right: 0px;
	//border-radius: 6px;
	border-left: 6px solid;
	border-right: 6px solid transparent;
	border-right-width: 6px;
	border-right-style: solid;
	border-right-color: rgba(0, 0, 0, 0) transparent;
	color: #7a7a7a;
}

blockquote {
	background-color: #fafafa;
	border-left-color: #e6e6e6;
	@extend %quotebox;
}

.important {
	background-color: #fbf8e8;
	border-left-color: #fee450;
	@extend %quotebox;
}

.warning {
	background-color: #f2dbdc;
	border-left-color: #ae272f;
	@extend %quotebox;
}

kbd {
	font-family: $root-font-family;
	padding: 2px 7px;
	border: 1px solid $gray-4;
	font-size: 0.8em;
	line-height: 1.4;
	background-color: #f3f3f3;
	color: $gray-9;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 2px $white inset;
	border-radius: 3px;
	display: inline-block;
	text-shadow: 0 1px 0 $white;
	white-space: nowrap;
}

a {
	//border-bottom: 1px dotted;
	color: $link-color;
	text-decoration: none;
	word-wrap: break-word;
	&.sidebar-nav-item {
		&:hover {
			@include link-no-decoration();
		}
		&:focus {
			@include link-no-decoration();
		}
	}
	&:focus {
		@include link-hover();
		&.tag {
			@include link-no-decoration();
		}
	}
	&:hover {
		@include link-hover();
		&.tag {
			@include link-no-decoration();
		}
	}
	&.tag {
		@include link-no-decoration();
	}
	&.read-more-symbol {
		text-decoration: none;
		@include link-no-decoration();
	}
}

a:visited{
	color:#7a7979;
}

.content {
	padding-bottom: 2rem;
	// padding-top: 2rem;
	padding-top: 0;
}

// 使用manyouit md主题
.manyouit-wrapper {
	font-family: "Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei",Arial,sans-serif;
	color: rgb(102, 102, 102);
	font-size: 16px;
  
  a {
	 text-decoration: underline;
  }
  img {
	  display: inline-block;
	  border-radius: 0;
  }

  .markdown-here-wrapper[data-md-url*="wordpress."] pre {
	background-color: transparent;
  }
  code{
	  font-size: 1em;
  }
  
  /* This spacing has been tweaked to closely match Gmail+Chrome "paragraph" (two line breaks) spacing.
  Note that we only use a top margin and not a bottom margin -- this prevents the
  "blank line" look at the top of the email (issue #243).
  */
  p {
	/* !important is needed here because Hotmail/Outlook.com uses !important to
	   kill the margin in <p>. We need this to win. */
	line-height: 1.9em;
	letter-spacing: 0.15em;
	font-size: 1em;
	color: slategrey;
	padding: 0 1em;
	margin-block-start: 0;
    margin-block-end: 0;
	del {
	  background-color: slategrey;
	}
  }
  p+p{
	margin: 2em 0 0 0;
  }
  p:first-child, p:first-of-type{
	margin-top: 0;
  }
  p:last-of-type{
	margin-bottom: 0;
  }
  div+h2,
  h1+p,
  h2+p,
  h3+p,
  h4+p,
  h5+p,
  h6+p {
	  margin-top:0;
  }
  
  table, pre, dl {
	margin: 0 0 1.2em ;
  }
  
  ul, ol {
	margin: 0 0 1em;
	padding-bottom: 0.2em;
	max-width: 100%;
	white-space: nowrap;
	overflow-x: scroll;
  }
  
  ol {
	padding-left: 2em;
	list-style-type: simp-chinese-informal;
  }
  ul{
	list-style-type: circle;
  }

  li {
	color: rgb(102, 102, 102);
	font-size: 0.8rem;
	color:slategrey;
	letter-spacing: 0.2em;
	line-height: 1rem;
	margin-left: 1em;
  }
  /* Space paragraphs in a list the same as the list itself. */
  li p {
	  /* Needs !important to override rule above. */
	  margin: 0.5em 0 !important;
	  display: inline;
  }
  
  /* Smaller spacing for sub-lists */
  ul ul, ul ol, ol ul, ol ol {
	margin: 0;
	padding-left: 1em;
	padding-bottom: 0;
  }
  
  /* Use Roman numerals for sub-ordered-lists. (Like Github.) */
  ol ol, ul ol {
	padding-left: 2em;
	list-style-type: lower-roman;
  }
  
  /* Use letters for sub-sub-ordered lists. (Like Github.) */
  ul ul ol, ul ol ol, ol ul ol, ol ol ol {
	list-style-type: lower-alpha;
  }
  
  dl {
	padding: 0;
  }
  
  dl dt {
	font-size: 1em;
	font-weight: bold;
	font-style: italic;
  }
  
  dl dd {
	margin: 0 0 1em;
	padding: 0 1em;
  }
  
  blockquote, q {
	// border-left: 4px solid #00a0e8;
	border-left: none;
	// border-top: 0.5px solid #00a0e8;
	border-bottom: 0.1px solid #00a0e8;
	padding: 0 0;
	color: #00a0e8;
	quotes: none;
	font-size: 0.9em;
	font-family: "sans-serif";
	p {
		color: rgba(112, 128, 144, 0.8);
	}
  
  }
  blockquote.collapse-tips {
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	border-left: none;
  }
  
  blockquote::before, blockquote::after, q::before, q::after {
	content: none;
  }
  
  /*
  blockquote::before, q::before{
	content: "«0000";
	color: blue;
  }
  */
  div+blockquote{
	  margin-top: 0;
  }
  h1 {  
	margin: 0.1em 0 0.5em 0;
	font-size: 1.2em;
	font-weight: 900;
	// border-bottom: 1px solid #ddd;
	color: #000;
	// background-color: #00a0e8;
	padding:  0.4em;
	border-radius: 2px;
	text-align: center;
  }
  
  h2 {
	margin: 1em 0 0;
	line-height: 1em;
	font-size: 1.6em;
	font-weight: 500;
	font-style: italic;
	// border-top: 0.5px solid #eee;
	color: #fff;
	background-color: #00a0e8;
	padding:  0.7em 0 ;
	// border-radius: 2px;
	text-align: center;

	span{
		font-size: 0.5em;
	}
	* {
		font-style: normal;
	}
  }
  h2::before{
	content: '▼';
	font-size: 0.6em;
  }
  
  h3, h4, h5, h6 {
	margin: 0.5em 0 0.5em;
	font-weight: 500;
	color: #00a0e8;
  }
  
  h3 {
	padding-left: 0.2em;
	font-size: 1.2em;
	font-weight: 500;
	// border-bottom: 0px solid #f0f0f0;
	border-left: 4px solid #00a0e8;
	vertical-align: top;
	line-height: 1.2em;
	margin: 2.5em 0 1.5em;
  }

  h2+h3 {
	margin: 1em 0 1.5em;
  }
//   h3:before{
// 	content: '▮▮▮ ';
//   }

  h4 {
	font-size: 1em;
	font-family: sans-serif;
  }
  
  h5 {
	font-size: 0.8em;
	color:#000;
  }
  
  h6 {
	font-size: 0.7em;
	color:black;
  }
  
  figure{
	  margin: 0;
  }
  
  table {
	  width: 100%;
	  padding: 0;
	  border-collapse: collapse;
	  border-spacing: 0;
	  font-size: 1em;
	  font: inherit;
	  border: 0;
	  font-size: 0.8em;
  }
  
  tbody {
	margin: 0;
	padding: 0;
	border: 0;
  }
  
  table tr {
	  border: 0;
	  border-top: 0.5px solid #CCC;
	  background-color: white;
	  margin: 0;
	  padding: 0;
	  color: slategrey;
  }
  
  
  table tr th, table tr td {
	font-size: 1em;
	border: 1px solid #CCC;
	margin: 0;
	padding: 0.5em 1em;
  }
  
  table tr th {
	  font-weight: bold;
	  background-color: #e8f4ff;
	  color: slategrey;
  }
  
  table tr:nth-child(2n) {
	  background-color: #e8f4ff;
	  color: slategrey;
  }
  table tr:nth-child(odd) td{
	background-color: #fff;
  }
  
  em {
	color: #00a0e8;
  }
  
  strong {
	font-size: inherit;
	color: #00a0e8
  }
//   用于设置贴吧表情
// v1
//   [class^="tieba-"] {
// 	vertical-align: text-bottom;
// 	display: inline-block; 
// 	box-sizing:content-box;
// 	border-collapse:collapse;
// 	background-image: url(/images/biaoqing/tieba.png);
// 	width: 30px;
// 	height: 30px;
//   }
  .tieba-in {
    // zoom:0.7;
    -moz-transform:scale(1);
    -moz-transform-origin: 0 0;
  }
//   v2
$bqSize: 1.2em;
  .tieba-in{
	width: $bqSize;
	height:  $bqSize;
	margin: 0;
	vertical-align: text-top;
  }
//   贴吧表情结束v1

  .pic {
	// margin-top: 3em;
	line-height: 0;
	width: 100%; 
	text-align: center; 
	background-color: rgb(250, 250, 250);
	img {
		vertical-align: text-bottom; 
		margin: 0; 
	}
	p{
		letter-spacing: 0; 
		font-size: 0.8em;
	}
  }
  .confidentialWrapper{
	  display: none;
  }
}